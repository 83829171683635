/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

:root {
    --colorredlight: #FCF6F6;
    --colorPurple: #9b1fe8;
    --colorRed: #AC1D24;
    --bodyColor: #fff9f9;
    --colorGrey: #626161;
    --colordkGrey: #2B2B2B;
    --colorDanger: #ff0000;
    --colorBlack: #000000;
    --graybg:#FBFBFB;
    --greenbtn:#4FC577;
    --btngray:#B4B4B4;
    --whitebg:#fff;
}
body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #626161;
    background-color: var(--bodyColor) !important;
    margin: 0px;
    padding: 0px;
}
.btn-black {
    background-color: #686363;
    color: #fff;
}
.redtext{color: var(--colorDanger);}
.borderradius{border-radius: 20px;}
.greenbtn{color: var(--greenbtn);}
/* login css */
.login_img{
    background-image:url('assets/images/backimg.png'); 
    background-repeat: no-repeat;
     background-size: cover; 
    height: 100vh;
    padding-top: 4%;
}
.login_block {
    background-color: #00000078;
    border-radius: 10px;
    padding: 35px;
    color: var(--bodyColor);
   .logo{margin-top: -110px;}  
   button{background-color:#AC1D24; width: 100%; height:60px; border-radius: 100px; border: 0px;}
   input{border: 1px solid #D2D2D2; border-radius: 100px; height: 60px;}
}
.green_btn{
    background-color: var(--greenbtn) !important;
    color: var(--bodyColor) !important;
    font-size: 16px;
    border: 1px solid var(--greenbtn) !important;
    padding: 3px 6px;
}
a{text-decoration: none !important; cursor: pointer;}
/* left side css */
aside {
    width: 100%;
    max-width: 300px;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    padding: 24px;
    background-color: #fff;
}
span.menu-icons {
    margin-right: 5px;
}
.list-group-item{background-color: transparent !important;    border-radius: 10px !important; border: 0px !important;}

.list-group-item.active{background-color:#AC1D24 !important; fill: white;}
/* center css */
.page-content-wrapper {
    padding: 24px 24px 24px 324px;
}
header{width: 100%;}

.hv100{height: calc(100vh - 48px);}

.card_box{
    border-radius: 10px; 
    display: flex; 
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}
.blue{background-color:#F2F3FF;}

.yellow_bg{background-color:#FFF5EC;}

.redlight{background-color:#FFF1F6;}

.BusinessScheduletdTable td, th, tr td {
    border-bottom-width: inherit !important;
    padding: 0.8rem 1.5rem !important;
}
.card-body table tr td {
    white-space: nowrap;
    vertical-align: middle;
}
table thead tr {
    background: #F2FAFF;
    border: none;
}
.avtar_50 {
    width: 50px;
    height: 50px;
    display: flex;
    overflow: hidden;
    border-radius: 6px;
}
.fit-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.btn-danger {
    background-color: rgba(255, 41, 33, 0.168627451);
    color: #FF2921;
    border-color: transparent;
    border-radius: 7px;
    padding: 7px 43px;
}
.card {
    border: 1px solid #EBEBEB !important;
}
.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff !important;
    border-color: #dee2e6;
}
.page-link{
    color: #000000 !important;
    
}
.page-item.active.page-link{
    z-index: 3;
    color: #fff;
    background-color: #000000 !important;
    border-color: #000000 !important;
}
.active>.page-link, .page-link.active {
    z-index: 3;
    color: #fff !important;
    background-color:#ab1d24 !important;
    border-color: #ab1d24 !important;
}
.pargh_block {
    width: 170px;
    /* word-break: break-word; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: normal;
}

ngb-rating span {
    fill: red;
    color: #FFC107;
}

body{background-color: #FBFBFB;}

/* ===============Sidebar CSS Start here================== */


#sidebar-wrapper .list-group-flush>.list-group-item{
    border-width: 0;
}
#sidebar-wrapper .list-group-item-light{
    background-color: transparent;
}
.list-group-flush>.list-group-item{
    color: #080808;
}

#sidebar-wrapper .list-group-item-light.list-group-item-action.active, #sidebar-wrapper .list-group-item-light.list-group-item-action:hover, #sidebar-wrapper .list-group-item-light.list-group-item-action:active, #sidebar-wrapper .list-group-item-light.list-group-item-action:focus {
    background-color:transparent;

}

#sidebar-wrapper .list-group-item-light.list-group-item-action svg path{
    fill: #4A4B68;
}

#sidebar-wrapper .CompleteYourProfile{
    margin: 0 16px;
}
// #sidebar-wrapper .list-group-item-light.list-group-item-action.active:before {
//     background: var(--colorRed);
//     content: "";
//     height: 71%;
//     width: 4px;
//     z-index: 9;
//     position: absolute;
//     top: 12px;
//     left: 0;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
// }


#sidebar-wrapper .list-group-item-light.list-group-item-action.active svg path{
    fill: var(--whitebg);
}
// #sidebar-wrapper .list-group-item-light.list-group-item-action:hover svg path,  #sidebar-wrapper .list-group-item-light.list-group-item-action:focus svg path {
//     fill: var(--colorRed);
// }
.btn-dark {
    background-color: #1c1f23;
    color: #fff;
}
// /* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
.heading_top {
    margin-top: -48px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid #1c1f23;
    padding: 5px 10px;
    color: #aebac9;
    background-color: #000;
}
.paginate_button.next, .paginate_button.previous {
    background-color: #1c1f23;
    border-radius: 4px;
    margin: 13px 10px;
    color: #fff;
    padding: 7px 15px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
    border: none;
    background-color: var(--colorRed) !important;
    background: var(--colorRed) !important;
    border-radius: 4px;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {

    padding:6px 11px;
}
.mat-mdc-snack-bar-container {
    margin: 8px;
    position: absolute !important;
    top: 0 !important;
}
.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: #fff !important;
}
.mdc-snackbar {
    top: 0px !important;
    bottom: inherit !important;
}
.pargh_block span {
    cursor: pointer;
}
.page-link{cursor: pointer;}